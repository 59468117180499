import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {
	GoogleAuthProvider,
	browserSessionPersistence,
	getAuth,
	setPersistence,
	signInWithPopup,
} from "firebase/auth"
import toast from "react-hot-toast"
import {checkValidAccount, checkValidEmail} from "./consts/functions"

const firebaseConfig = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	databaseURL: process.env.REACT_APP_databaseURL,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
	measurementId: process.env.REACT_APP_measurementId,
}
export const app = initializeApp(firebaseConfig)
export const firestoreDB = getFirestore(app)

const provider = new GoogleAuthProvider()
const auth = getAuth()

export function fbLogout() {
	auth.signOut().then(() => {
		console.log("로그아웃됨")

		sessionStorage.setItem("token", "")
		localStorage.removeItem("token")
		localStorage.removeItem("email")
	})
}

export async function getAuthentication() {
	try {
		const result = await signInWithPopup(auth, provider)

		const credential = GoogleAuthProvider.credentialFromResult(result)
		const token = credential?.idToken
		const user = result.user

		console.log(result)
		const mail = user.email
		console.log(mail, token)

		if (!mail) return
		console.log(checkValidEmail(mail))

		if (checkValidEmail(mail) && token) {
			// sessionStorage.setItem("token", token)
			toast.success("로그인 성공")
			return result
		} else if (!checkValidEmail(mail)) {
			console.warn("유효한 이메일이 아님: ", mail)
			toast.error("유효한 이메일이 아닙니다")
			setPersistence(auth, browserSessionPersistence).then(() => {
				return signInWithPopup(auth, provider)
			})
		} else {
			console.warn("토큰이 없음")
		}
	} catch (error: any) {
		const credential = GoogleAuthProvider.credentialFromError(error)
		toast.error("로그인 중 오류발생")

		console.log(credential)
	}
}
