export const meatInfoUrl = process.env.REACT_APP_meatInfoUrl

export const baseUrl = ""

export const foreignMeatInfoUrl =
	"http://www.meatwatch.go.kr/xml/selectDistbHistInfoWsrvDetail.do"
export const SYS_ID = process.env.REACT_APP_SYS_ID

export const bizNoUrl = process.env.REACT_APP_biznoUrl

export const pyverseUrl = process.env.REACT_APP_recentPyverseUrl
